import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./ContactForm.scss";
import ScrollAnimation from "react-animate-on-scroll";
import ContactUsForm from "../../forms/contact-us-form";
import CareerFormPage from "../../forms/career-form";

const ContactForm = (props) => {
    return (
        <div className="application-form">
            <Container>
                <Row>
                    <Col xl={6}>
                        {typeof props.page_type == "undefined" && <ScrollAnimation animateIn="fadeInLeft" animateOnce>
                            <div className="app-head">
                                <h2 className="main-title">We're always <span>here to help.</span><br/>Book a call with our team</h2>
                                <p className="form-desc">As a truly customer-centric real estate agency, we offer a range of related services that are designed to make your property journey stress-free and successful from start to end.</p>
                            </div>
                        </ScrollAnimation>}
                        {props?.page_type == "career-landing" &&  
                            <div className="app-head">
                                <h2 className="main-title">We're always on the lookout <br/> for <span>standout candidates.</span></h2>
                                <p className="form-desc">If you are passionate about customer service and property trends in Dubai, get in touch to find out our current and prospective opportunities to grow your career.</p>
                            </div>
                        }
                        {props?.page_type == "holiday-homes" &&  
                            <div className="app-head">
                                <h2 className="main-title">Get in touch with our <span>Holiday Homes team</span><br/>Book a call with our team</h2>
                                <p className="form-desc">As a truly customer-centric real estate agency, we offer a range of related services that are designed to make your property journey stress-free and successful from start to end.</p>
                            </div>
                        }
                        {props?.page_type == "career-detail" &&  
                            <div className="app-head">
                                <h2 className="main-title">Are you <span>ready to apply</span>? <br/>Submit your application.</h2>
                                <p className="form-desc">If you are passionate about customer service and property trends in Dubai, get in touch to find out our current and prospective opportunities to grow your career.</p>
                            </div>
                        }
                    </Col>
                    <Col xl={6} className="application-padd-form">
                        <ScrollAnimation animateIn="fadeInRight" animateOnce>
                            <div className="application-wrp">
                                {props.career ? <CareerFormPage title={props.title} {...props}/> : <ContactUsForm {...props}/> }
                            </div>
                        </ScrollAnimation>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};
export default ContactForm;
