import React from 'react'
import { Form } from "react-bootstrap"
import Col from "react-bootstrap/Col"

const FileField = ({fieldClass, labelClass, type, name, value, required, placeholder, handlechange, accept, label, grpmd, id}) => (
    <Form.Group as={Col} md={grpmd} controlId={"validation" + name}>
        { label
            ? <Form.Label className={labelClass}>{label}{required ? '*' : ''}</Form.Label>
            : ''
        }
        <div className={fieldClass}>
            <Form.Control
                required={required}
                type={type}
                name={name}
                value={value}
                onChange={handlechange}
                accept={accept}
                id={id}
                className="d-none"
            />
            <label htmlFor={id} className="custom-file-label">{placeholder}</label>
        </div>
    </Form.Group>
);

export default FileField;